.image-track-container {
    position: relative;
    width: 100%;
    height: 56vmin;
    overflow: hidden;
}

#image-track {
    display: flex;
    gap: 4vmin;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

#image-track > .image {
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% center;
}
